import {createStore} from 'vuex'
import StoreAutomats from './modules/automats/automats'
import StoreTaretype from './modules/taretype/taretype'
import StoreEvents from './modules/events/events'
import StoreMonitor from './modules/monitor/monitor'
import StoreAlert from './modules/alert/alert'
import StoreConnections from './modules/connections/connections'
import StoreCollections from './modules/collections/collections'
import StoreAutomatGroup from './modules/automatgroup/automatgroup'
import StorePeople from './modules/people/people'
import StoreRemains from './modules/remains/remains'
import StoreDashboard from './modules/dashboard/dashboard'


export default createStore({
  /*state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },*/
  modules: {
    automats: StoreAutomats,
    taretype: StoreTaretype,
    events: StoreEvents,
    monitor: StoreMonitor,
    alert: StoreAlert,
    connections: StoreConnections,
    collections: StoreCollections,
    automatgroup: StoreAutomatGroup,
    people: StorePeople,
    remains: StoreRemains,
    dashboard: StoreDashboard
  }
})
