<template>
  <Automats></Automats>
</template>
<script>
// @ is an alias to /src
import Automats from '@/components/Automats.vue'

export default {
	name: 'VAutomats',
	components: {
		Automats
	}
}
</script>
