<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="3">
              Остатки
            </v-col>
            <v-col
              cols="8"
              class="text--secondary"
              >
              <v-fade-transition leave-absolute>
                <span v-if="open">Установите настройки</span>
                <v-row
                  v-else
                  no-gutters
                  style="width: 100%"
                  >
                  <v-col cols="4">
                    Киоск: {{ selectListAutomat.comment || 'Не выбран' }}
                  </v-col>
                  <v-col cols="4">
                    Дата начала: {{ startDate || 'Не выбран' }}
                  </v-col>
                  <v-col cols="4">
                    Дата конца: {{ endDate || 'Не выбран' }}
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            justify="space-around"
            no-gutters
          >
            <v-col cols="3">
              <v-menu
                ref="kioskMenu"
                :close-on-content-click="false"
                :return-value.sync="selectListAutomat.comment"
                offset-y
                min-width="290px"
              >
                <template>
                  <v-select
                    v-model="selectListAutomat"
                    :items="listPropertyAutomat"
                    label="Киоск"
                    item-text="comment"
                    item-value="id"
                    return-object
                    single-line
                    required
                    clearable
                    >
                  </v-select>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="startMenu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Дата начала"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startMenu.isActive = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startMenu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3">
              <v-menu
                ref="endMenu"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="Дата конца"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endMenu.isActive = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endMenu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-btn
                icon
                color="green"
                @click="refreshreport"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      sort-by="comment"
      class="elevation-1"
      :loading="loading"
      loading-text="Загрузка... Подождите"
      :items-per-page="-1"
      fixed-header
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Остатки</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
            ></v-divider>
          <v-spacer></v-spacer>
          <v-overlay :value="isLoadingForm">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        </v-toolbar>
      </template>
      <template v-slot:[`item.water_percent`]="{ item }">
        <span>{{new Intl.NumberFormat("ru", {style: "percent", minimumFractionDigits: 2}).format(item.water_percent)}}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
    getterType: 'remains/getField',
    mutationType: 'remains/updateField',
});

export default {
    name: 'v-remains',
    computed: {...mapFields([
        'items',
        'loading',
        'isLoadingForm',
        'textError',
        'errorSnackbar',
        'formDialog',
        'editedItem',
        'editedIndex',
        'startDate',
        'endDate',
        'selectListAutomat',
        'listPropertyAutomat'
    ])},
    created () {
        var self = this;
        self.$store.dispatch('remains/GET_LIST')
        self.loadAutomats()
    },
    methods: {
        refreshreport () {
            this.$store.dispatch('remains/GET_LIST')
        },
        loadAutomats () {
            this.$store.dispatch('remains/SET_AUTOMATS')
        }
    },
    watch: {
        errorSnackbar (newVal) {
            if (newVal) {
                this.$toast.error(this.textError, {
                    timeout: 10000,
                    icon: 'error',
                    dismissable: false,
                    showClose: true
                })
            }
        },
        formDialog (val) {
            val || this.close()
        }
    },
    data: () => ({
        formTitle: '',
        menu: false,
        date: null,
        headers: [
            { text: 'Автомат', value: 'comment', width: '25%' },
            { text: 'Остаток воды, л', value: 'ost', width: '20%' },
            { text: 'Остаток воды, %', value: 'water_percent', width: '20%' },
            { text: 'Остаток тары 5л', value: 'coins_in_tray', width: '10%' },
            { text: 'Остаток тары 19л', value: 'coins' , width: '10%'},
            { text: 'Остаток тары 2л', value: 'banknote_before' , width: '10%'}
        ]
    })
};
</script>>
