<template>
<v-app>
  <div>
	<v-navigation-drawer app v-model="drawer" class="deep-purple accent-4"
                         dark
                         absolute
                         temporary
                         >
      <v-list-item>
          <v-list-item-title class="title">
            Вода Находка
          </v-list-item-title>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
      </v-list-item>
      <v-divider></v-divider>
      <v-list v-model:opened="open">
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :value="item.title"
          :prepend-icon="item.icon"
          no-action
          >
          <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                :title="item.title"></v-list-item>
          </template>
          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="subItem.link"
            :title="subItem.title"
            >
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="deep-purple accent-4"
               dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title>Автоматы</v-toolbar-title>
    </v-app-bar>
  </div>
  <!-- Sizes your content based upon application components -->
  <v-main>
	<!-- Provides the application the proper gutter -->
	<v-container fluid>
	<!-- If using vue-router -->
  <router-view></router-view>
	</v-container>
  </v-main>
  <v-footer>
	<!-- -->
  </v-footer>
</v-app>
</template>

<script>

export default {
    name: 'App',
    data: () => ({
      currentRoute: window.location.pathname,
      open: 'Монитор',
      drawer: false,
      items: [
        { title: 'Монитор', icon: 'mdi-view-dashboard',
          items : [{ title : 'События', icon: 'gavel',link:'/monitor'},
                   { title: 'Тревога', icon: 'mdi-gavel', link:'/alert'},
                   { title : 'Связь', icon: 'gavel',link:'/connections'}
                  ]
        },
        { title: 'Остатки', icon: 'mdi-gavel',
          items : [{ title: 'Остатки', icon: 'mdi-gavel', link: '/remains'}]
        },
        { title: 'Отчеты', icon: 'mdi-gavel' ,
          items : [{title : 'Продажи', icon: 'mdi-gavel', link:'/sales'},
                   {title : 'Вода', icon: 'mdi-gavel',link:'/'},
                   {title : 'Периоды', icon: 'mdi-gavel',link:'/'},
                   {title : 'Типы', icon: 'mdi-gavel',link:'/'},
                   {title : 'Реестр Инкасcаций', icon: 'mdi-gavel',link:'/collections'},
                   {title : 'Инкасс.Свободный', icon: 'mdi-gavel',link:'/'},
                   {title : 'Инкасаторы', icon: 'mdi-gavel',link:'/'}]},
        { title: 'Настройки', icon: 'mdi-gavel',
          items : [{title : 'Группы', icon: 'mdi-gavel',link:'/automatgroup'},
                   {title : 'Автоматы', icon: 'mdi-gavel',link:"/automats"},
                   {title : 'Товары', icon: 'mdi-gavel',link:'/taretype'},
                   {title : 'Коды собщений', icon: 'mdi-gavel',link:'/events'},
                   {title : 'Кассиры', icon: 'mdi-gavel',link:'/people'},
                   {title : 'Инкассаторы', icon: 'mdi-gavel',link:'/'},
                   {title : 'Общие', icon: 'mdi-gavel',link:'/'}]},
        ]
    }),
};
</script>
