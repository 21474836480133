<template>
  <AutomatGroup></AutomatGroup>
</template>
<script>
// @ is an alias to /src
import AutomatGroup from '@/components/Automatgroup.vue'

export default {
	name: 'VAutomatGroup',
	components: {
		AutomatGroup
	}
}
</script>
