<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      sort-by="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Загрузка... Подождите"
      show-select
      fixed-header
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Сотрудники</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
            >
          </v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="newItem">Добавить</v-btn>
          <v-dialog v-model="formDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.name" label="ФИО"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Записать</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-overlay :value="isLoadingForm">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
    getterType: 'people/getField',
    mutationType: 'people/updateField',
});

export default {
    name: 'v-people',
    computed: {...mapFields([
                  'items',
                  'editedItem',
                  'editedIndex',
                  'defaultItem',
                  'loading',
                  'isLoadingForm',
                  'textError',
                  'errorSnackbar',
                  'formDialog',
            ]),
            formTitle () {
                return this.editedIndex === -1 ? 'Новый сотрудник' : 'Редактировать сотрудника'
            }
    },
    beforeMount() {
        this.$store.dispatch('people/FORM')
    },
    created () {
        this.$store.dispatch('people/GET_LIST')
    },
    watch: {
        errorSnackbar (newVal) {
            if (newVal) {
                this.$toast.error(this.textError, {
                    timeout: 10000,
                    icon: 'error',
                    dismissable: false,
                    showClose: true
                })
            }
        },
        formDialog (val) {
            val || this.close()
        }
    },
    methods: {
        close () {
            this.$store.dispatch('people/SET_FORMDIALOG', false)
        },
        editItem (item) {
            this.$store.dispatch('people/SET_EDITED_ITEM', { item: item })
        },
        newItem () {
            this.$store.dispatch('people/SET_EDITED_ITEM', { item: this.defaultItem })
        },
        deleteItem (item) {
            confirm('Вы действительно хотите удалить сотрудника ?') &&
                this.$store.dispatch('people/DELETE', { item: item })
        },
        selectItem (isSelected, item) {
            this.$store.dispatch('people/SET_SELECTED_ITEM', { item: item, isSelected: isSelected })
        },
        save () {
            if (this.editedItem.id === -1) {
                this.$store.dispatch('people/INSERT', { item: this.editedItem })
            } else {
                this.$store.dispatch('people/UPDATE', { item: this.editedItem })
            }
        },
    },
    data: () => ({
        headers: [
            { text: 'ФИО', value: 'name' },
            { text: 'Action', value: 'action' , sortable: false }
        ]
    })
};
</script>>
