<template>
  <Connections></Connections>
</template>
<script>
// @ is an alias to /src
import Connections from '@/components/Connections.vue'

export default {
    name: 'VConnections',
    components: {
        Connections
    }
}
</script>
