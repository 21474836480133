/* global cotonic */
import { getField, updateField } from 'vuex-map-fields'

const state = {
	items: [],
	selectedItems: [],
    loading: false,
    isLoadingForm: false,
	errorSnackbar: '',
	textError: '',
	listPropertyCashier: [],
	listPropertyCollector: [],
	listPropertyAutomat: [],
    selectListCashier: {id: '', name: ''},
	selectListCollector: {id: '', name: ''},
	selectListAutomat: {id: '', comment: ''},
	startDate: new Date().toLocaleString('sv').substr(0, 10),
	endDate: new Date().toLocaleString('sv').substr(0, 10),
	editedItem: {},
	editedIndex: -1,
	formDialog: false
}

const getters = {
	LIST: state => {
		return state.items
	},
	GETLOADINGFORM: state => {
		return state.isLoadingForm
	},
	GETEDITEDITEM: state => {
		return state.editedItem
	},
	GETEDITEDINDEX: state => {
		return state.editedIndex
	},
	GETLOADING: state => {
		return state.loading
	},
    GETTEXTERROR: state => {
		return state.textError
	},
	GETERRORSNACKBAR: state => {
		return state.errorSnackbar
	},
    getField
}

const mutations = {
    SET_LOADING: (state, payload) => {
		state.loading = payload
	},
	SET_FORMLOADING: (state, payload) => {
		state.isLoadingForm = payload
	},
    SET_LIST: (state, payload) => {
		//Vue.set(state, 'items', payload)
		state.items = payload
		state.loading = false
	},
    SET_TEXTERROR: (state, payload) => {
		state.textError = payload
		state.errorSnackbar = true
		state.isLoadingForm = false
	},
    INSERT: (state, payload) => {
		//Vue.set(state.items, state.editedIndex, payload.item)
		state.items[state.editedIndex] = payload.item
		state.loading = false
		state.formDialog = false
	},
	UPDATE: (state, payload) => {
		//Vue.set(state.items, state.editedIndex, payload)
		state.items[state.editedIndex] = payload
		state.isLoadingForm = false
		state.formDialog = false
	},
	UNSHIFT: (state, payload) => {
		const index = state.items.findIndex(function(e) {
			return e.phone_imei === payload.phone_imei
		})
		if (index !== -1) {
			//Vue.set(state.items[index], 'event_date', payload.event_date)
			state.items[index].event_date = payload.event_date
		}
		else {
			//Vue.set(state.items[0], '', payload)
			state.items[0] = payload
		}
	},
	BANK: (state, payload) => {
		const index = state.items.findIndex(function(e) {
			return e.phone_imei === payload.phone_imei
		})
		if (index !== -1) {
			//Vue.set(state.items, index, payload)
			state.items[index] = payload
		}
		else {
			//Vue.set(state.items, 0, payload)
			state.items[0] = payload
		}
	},
	SET_SNACKBAR: (state, payload) => {
		state.errorSnackbar = payload
	},
	SET_EDITED_ITEM: (state, payload) => {
		state.editedIndex = state.items.indexOf(payload)
		state.editedItem = Object.assign({}, payload)
		state.formDialog = true
	},
	SET_CASHIERLIST: (state, payload) => {console.log(payload)
		state.listPropertyCashier = payload
		const index = state.listPropertyCashier.map(function (e) {
			return e.id
		}).indexOf(state.editedItem.cashier_id)
                                          console.log(state.editedItem.cashier_id)
                                          console.log(index)
		state.selectListCashier = Object.assign({},state.listPropertyCashier[index])
	},
	SET_COLLECTORLIST: (state, payload) => {
		state.listPropertyCollector = payload
		const index = state.listPropertyCollector.map(function (e) {
			return e.id
		}).indexOf(state.editedItem.collector_id)
		state.selectListCollector = Object.assign({},state.listPropertyCollector[index])
	},
	SET_FORMDIALOG: (state, payload) => {
		state.formDialog = payload
		state.editedItem = Object.assign({}, state.defaultItem)
	},
	SET_AUTOMATS: (state, payload) => {
		state.listPropertyAutomat = payload
	},
    updateField
}

const actions = {
	GET_LIST: async (context) => {
		context.commit('SET_LOADING', true)
		/*await axios.post('/api/model/collections/post/list', {
			automat : context.state.selectListAutomat.id,
			data : {
				startDate: context.state.startDate,
				endDate: context.state.endDate
			}
		},
						)
            .then(data => {
				if (data.status === 200) {
					context.commit('SET_LIST', data.data.result)
					context.commit('SET_LOADING', false)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})*/
        let response = await fetch('/api/model/collections/post/list', {
            method: 'POST',
            //mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                automat : context.state.selectListAutomat.id,
                data : {
                    startDate: context.state.startDate,
                    endDate: context.state.endDate
                }
            })
        });
        try {
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'ok') {
                    context.commit('SET_LIST', result.result)
                    context.commit('SET_LOADING', false)
                }
            }
            else {
                context.commit('SET_TEXTERROR', response.statusText)
                context.commit('SET_LOADING', false)
                throw new Error(`Request failed with status ${response.status}`);
            }
        }
        catch(error)  {
            console.error(`Error in load function for : ${error}`)
            context.commit('SET_TEXTERROR', error)
            context.commit('SET_LOADING', false)
        }
	},
	SET_AUTOMATS: async (context) => {
		/*await axios.post('/api/model/automats/post/list', { }
						)
            .then(data => {
				if (data.status === 200) {
					context.commit('SET_AUTOMATS', data.data.result)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})*/
        let response = await fetch('/api/model/automats/post/list', {
            method: 'POST',
            //mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
        try {
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'ok') {
                    context.commit('SET_AUTOMATS', result.result)
                }
            }
            else {
                context.commit('SET_TEXTERROR', response.statusText)
                context.commit('SET_LOADING', false)
                throw new Error(`Request failed with status ${response.status}`);
            }
        }
        catch(error)  {
            console.error(`Error in load function for : ${error}`)
            context.commit('SET_TEXTERROR', error)
            context.commit('SET_LOADING', false)
        }
	},
	SET_FORMDIALOG: async (context, payload) => {
		context.commit('SET_FORMDIALOG', payload)
	},
	UNSHIFT: async (context, payload) => {
		context.commit('UNSHIFT', payload)
	},
	BANK: async (context, payload) => {
		context.commit('BANK', payload)
	},
	SET_SNACKBAR: async (context, payload) => {
		context.commit('SET_SNACKBAR', payload)
	},
	SET_EDITED_ITEM: async (context, payload) => {
		await context.commit('SET_EDITED_ITEM', payload.selectable)
		const cashierlist = await cotonic.broker.call('bridge/origin/model/people/post/list', {},
								{ qos: 1,timeout: 1000 })
		if (cashierlist.payload.status === 'ok') {
			await context.commit('SET_CASHIERLIST', cashierlist.payload.result)
		}
		const collectorlist = await cotonic.broker.call('bridge/origin/model/people/post/list', {},
								{ qos: 1,timeout: 1000 })
		if (collectorlist.payload.status === 'ok') {
			await context.commit('SET_COLLECTORLIST', collectorlist.payload.result)
		}
	},
	UPDATE: async (context) => {
		context.commit('SET_FORMLOADING', true)
		await axios.post('/api/model/collections/post/update',
			context.getters.GETEDITEDITEM
		)
			.then(data => {
				if (data.data.status === 'ok') {
					context.commit('UPDATE', data.data.result)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
