import Vue from 'vue'
import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'

const state = {
	items: [],
	selectedItems: [],
	editedItem: {},
	editedIndex: -1,
    loading: false,
    isLoadingForm: false,
    formDialog: false,
    defaultItem: {
        id: -1,
		description: '',
		code: '',
		alert_flag: 0,
		sms_flag:0,
		p1:0,
		p2:0,
		p3:0,
		p4:0,
		p5:0,
		p6:0,
		p7:0,
		p8:0,
		p9:0,
		p10:0,
        action: ''
	}
}

const getters = {
	LIST: state => {
		return state.items
	},
	GETEDITEDITEM: state => {
		return state.editedItem
	},
	GETEDITEDINDEX: state => {
		return state.editedIndex
	},
	GETDEFAULTITEM: state => {
		return state.defaultItem
	},
	GETSELECTEDITEM: state => {
		return state.selectedItems
	},
	GETLOADING: state => {
		return state.loading
	},
	GETLOADINGFORM: state => {
		return state.isLoadingForm
	},
    GETTEXTERROR: state => {
		return state.textError
	},
	GETERRORSNACKBAR: state => {
		return state.errorSnackbar
	},
	GETFORMDIALOG: state => {
		return state.formDialog
	},
    getField
}

const mutations = {
    SET_LOADING: (state, payload) => {
		state.loading = payload
	},
	SET_FORMLOADING: (state, payload) => {
		state.isLoadingForm = payload
	},
	SET_FORMDIALOG: (state, payload) => {
		state.formDialog = payload
		state.editedItem = Object.assign({}, state.defaultItem)
	},
    SET_LIST: (state, payload) => {
		state.items = payload
		state.loading = false
	},
    FORM: (state, payload) => {
		state.forma = payload
	},
    SET_TEXTERROR: (state, payload) => {
		state.textError = payload
		state.errorSnackbar = true
		state.isLoadingForm = false
	},
    INSERT: (state, payload) => {
		state.items[state.editedIndex] = payload.item
		state.loading = false
		state.formDialog = false
	},
	UPDATE: (state, payload) => {
		state.items[state.editedIndex] = payload
		state.isLoadingForm = false
		state.formDialog = false
	},
    DELETE: (state, payload) => {
		const index = state.items.indexOf(payload.item)
		state.items[index].deltag = !state.items[index].deltag
	},
	SET_EDITED_ITEM: (state, payload) => {
		state.editedIndex = state.items.indexOf(payload.selectable)
		state.editedItem = Object.assign({}, payload.selectable)
		state.formDialog = true
	},
	SET_SNACKBAR: (state, payload) => {
		state.errorSnackbar = payload
	},
    updateField
}

const actions = {
	GET_LIST: async (context) => {
		//context.commit('SET_LOADING', true)
		await axios.post('/api/model/events/post/list', {
			vars: [context.state.forma]
		})
            .then(data => {
				if (data.status === 200) {
					context.commit('SET_LIST', data.data.result)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})
	},
    FORM: async (context, payload) => {
		context.commit('FORM', payload)
	},
    SET_FORMDIALOG: async (context, payload) => {
		context.commit('SET_FORMDIALOG', payload)
	},
    UPDATE: async (context) => {
		context.commit('SET_FORMLOADING', true)
		await axios.post('/api/model/events/post/update',
			context.getters.GETEDITEDITEM
		)
			.then(() => {
				context.commit('UPDATE', context.getters.GETEDITEDITEM)
				context.commit('SET_FORMLOADING', false)
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
			})
	},
    DELETE: async (context, payload) => {
		await axios.post('/api/model/events/post/delete', payload.item)
			.then(() => {
				context.commit('DELETE', payload)
				context.commit('SET_FORMLOADING', false)
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
			})
	},
	INSERT: async (context, payload) => {
		context.commit('SET_FORMLOADING', true)
		await axios.post('/api/model/events/post/insert', payload.item)
			.then(() => {
				context.commit('INSERT', payload)
				context.commit('SET_FORMLOADING', false)
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
			})
	},
	SET_EDITED_ITEM: async (context, payload) => {
			context.commit('SET_EDITED_ITEM', payload)
	},
    SET_EDITED_INDEX: async (context, payload) => {
		context.commit('SET_EDITED_INDEX', payload)
	},
	SET_SELECTED_ITEM: async (context, payload) => {
		context.commit('SET_SELECTED_ITEM', payload)
	},
	SET_SNACKBAR: async (context, payload) => {
		context.commit('SET_SNACKBAR', payload)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
