import 'vuetify/styles'
import { createVuetify } from 'vuetify'
//import VueI18n from './i18n';
import { camelize, h } from 'vue'
import { ru, en } from 'vuetify/locale'
import '@mdi/font/css/materialdesignicons.css'
import { md } from 'vuetify/iconsets/md'

import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import { mdi } from 'vuetify/iconsets/mdi-svg'
import * as mdiSvg from './icons'
//Vue.use(Vuetify, {});

export default createVuetify({
  components: {
    ...labsComponents,
    ...components,
  },
  directives,
  ssr: true,
  locale: {
    locale: 'en',
    fallbackLocale: 'en',
    fallback: 'en',
    },
    messages: { ru, en },
    lang: {
        locales: 'en_EN',
        current: 'en'
    },
    icons: {
      defaultSet: 'mdi',
      sets: {
        md,
        mdiSvg: mdi,
        mdi: {
          component: props => {
            const icon = mdiSvg[camelize(props.icon)]
            return h(components.VSvgIcon, { ...props, icon })
          },
        },
      },
    },
    theme: {
      themes: {
        defaultTheme: 'light',
        light: {
          colors: {
            primary: '#1867c0',
            secondary: '#5CBBF6',
            tertiary: '#E57373',
            accent: '#005CAF',
            quarternary: '#B0D1E8',
          },
        },
        dark: {
          colors: {
            primary: '#2196F3',
            secondary: '#424242',
            tertiary: '#E57373',
            accent: '#FF4081',
            quarternary: '#B0D1E8',
          },
        }
      }
    }
})

/*export default new Vuetify({
    locale: {
        locale: 'ru'
    },
    messages: { ru },
    lang: {
        locales: 'ru_RU',
        current: 'ru'
    },
    icons: {
        iconfont: 'mdi'
    }
});*/
