import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'

const state = {
	items: [],
	selectedItems: [],
    loading: false,
    isLoadingForm: false,
	errorSnackbar: '',
	textError: ''
}

const getters = {
	LIST: state => {
		return state.items
	},
	GETLOADINGFORM: state => {
		return state.isLoadingForm
	},
	GETEDITEDITEM: state => {
		return state.editedItem
	},
	GETEDITEDINDEX: state => {
		return state.editedIndex
	},
	GETLOADING: state => {
		return state.loading
	},
    GETTEXTERROR: state => {
		return state.textError
	},
	GETERRORSNACKBAR: state => {
		return state.errorSnackbar
	},
    getField
}

const mutations = {
    SET_LOADING: (state, payload) => {
		state.loading = payload
	},
	SET_FORMLOADING: (state, payload) => {
		state.isLoadingForm = payload
	},
    SET_LIST: (state, payload) => {
		state.items = payload
		state.loading = false
	},
    SET_TEXTERROR: (state, payload) => {
		state.textError = payload
		state.errorSnackbar = true
		state.isLoadingForm = false
	},
    INSERT: (state, payload) => {
		state.items[state.editedIndex] = payload.item
		state.loading = false
		state.formDialog = false
	},
	UNSHIFT: (state, payload) => {
		const index = state.items.findIndex(function(e) {
			return e.phone_imei === payload.phone_imei
		})
		if (index !== -1) {
			state.items[index].event_date = payload.event_date
		}
		else {
			state.items.push(payload)
		}
	},
	BANK: (state, payload) => {
		const index = state.items.findIndex(function(e) {
			return e.phone_imei === payload.phone_imei
		})
		if (index !== -1) {
			state.items[index] = payload
		}
		else {
			state.items.push(payload)
		}
	},
	SET_SNACKBAR: (state, payload) => {
		state.errorSnackbar = payload
	},
	PING: (state, payload) => {
		const index = state.items.findIndex(function(e) {
			return e.id === payload.id
		})
		//Vue.set(state.items, index, payload)
		state.items[index] = payload
	},
    updateField
}

const actions = {
	GET_LIST: async (context) => {
		//context.commit('SET_LOADING', true)
		await axios.post('/api/model/connections/post/list', { event : 'alert'}
						)
            .then(data => {
				if (data.status === 200) {
					context.commit('SET_LIST', data.data.result)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})
	},
	UNSHIFT: async (context, payload) => {
		context.commit('UNSHIFT', payload)
	},
	BANK: async (context, payload) => {
		context.commit('BANK', payload)
	},
	SET_SNACKBAR: async (context, payload) => {
		context.commit('SET_SNACKBAR', payload)
	},
	PING: async (context) => {
		let items = context.getters.LIST

		items.forEach(element =>
			{
				if (new Date() - new Date(element.event_date) > 600) {
					Object.assign(element, {no_connected : true})
					context.commit('PING', element)
				}
				else {
					Object.assign(element, {no_connected : false})
					context.commit('PING', element)
				}
			})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
