<template>
<Alert></Alert>
</template>
<script>
// @ is an alias to /src
import Alert from '@/components/Alert.vue'

export default {
    name: 'VAlert',
    components: {
        Alert
    }
}
</script>
