<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title v-slot:activator="{ open }">
          <v-row no-gutters>
            <v-col cols="3">
              Настройки
            </v-col>
            <v-col
              cols="8"
              class="text--secondary"
              >
              <v-fade-transition leave-absolute>
                <span v-if="open">Установите настройки</span>
                <v-row
                  v-else
                  no-gutters
                  style="width: 100%"
                  >
                  <v-col cols="4">
                    Киоск: {{ selectListAutomat.comment || 'Не выбран' }}
                  </v-col>
                  <v-col cols="4">
                    Дата начала: {{ startDate || 'Не выбран' }}
                  </v-col>
                  <v-col cols="4">
                    Дата конца: {{ endDate || 'Не выбран' }}
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row
            justify="space-around"
            no-gutters
          >
            <v-col cols="3">
              <v-menu
                ref="kioskMenu"
                :close-on-content-click="false"
                :return-value.sync="selectListAutomat.comment"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-select
                    v-model="selectListAutomat"
                    :items="listPropertyAutomat"
                    label="Киоск"
                    item-title="comment"
                    item-value="id"
                    return-object
                    single-line
                    required
                    clearable
                    >
                  </v-select>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="startDate"
                label="Дата начала"
                type="date"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="endDate"
                label="Дата конца"
                type="date"
                ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                icon
                color="green"
                @click="refreshreport"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      loading-text="Загрузка... Подождите"
      :items-per-page="-1"
      fixed-header
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Тревога</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-overlay
            :value="isLoadingForm">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-toolbar>
      </template>
      <template v-slot:item.event_date="{ item }">
        <span>{{new Date(item.columns.event_date).toISOString().slice(0,19).replace(/T/, ' ')}}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
/* global cotonic */
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
    getterType: 'alert/getField',
    mutationType: 'alert/updateField',
});

export default {
    name: 'v-alert',
    computed: {...mapFields([
        'items',
        'loading',
        'isLoadingForm',
        'textError',
        'errorSnackbar',
        'startDate',
        'endDate',
        'selectListAutomat',
        'listPropertyAutomat'
    ])},
    created () {
        var self = this;
        self.$store.dispatch('alert/GET_LIST')
        self.subscribe()
        self.loadAutomats()
    },
    methods: {
        subscribe() {
            var self = this
            cotonic.broker.subscribe("bridge/origin/public/alert", (function(msg) {
                self.$store.dispatch('alert/UNSHIFT', msg.payload)
            }).bind(self))
        },
        loadAutomats () {
            this.$store.dispatch('alert/SET_AUTOMATS')
        },
        refreshreport () {
            this.$store.dispatch('alert/GET_LIST')
        }
    },
    watch: {
        errorSnackbar (newVal) {
            if (newVal) {
                this.$toast.error(this.textError, {
                    timeout: 10000,
                    icon: 'error',
                    dismissable: false,
                    showClose: true
                })
            }
        },
        formDialog (val) {
            val || this.close()
        }
    },
    data: () => ({
        formTitle: '',
        menu: false,
        date: null,
        headers: [
            { title: 'Автомат', key: 'automat', align: 'start', sortable: true },
            { title: 'Время', key: 'event_date', align: 'end', sortable: true },
            { title: 'Код', key: 'code', align: 'end', sortable: false },
            { title: 'Событие', key: 'event', align: 'end', sortable: false },
            { title: 'P1', key: 'p1', align: 'end', sortable: false },
            { title: 'P2', key: 'p2', align: 'end', sortable: false },
            { title: 'P3', key: 'p3', align: 'end', sortable: false },
            { title: 'P4', key: 'p4', align: 'end', sortable: false },
            { title: 'P5', key: 'p5', align: 'end', sortable: false },
            { title: 'P6', key: 'p6', align: 'end', sortable: false },
            { title: 'P7', key: 'p7', align: 'end', sortable: false },
            { title: 'P8', key: 'p8', align: 'end', sortable: false },
            { title: 'P9', key: 'p9', align: 'end', sortable: false },
            { title: 'P10', keyЭ: 'p10', align: 'end', sortable: false }
        ]
    })
};
</script>>
