<template>
<div>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="imei"
    sort-by="imei"
    class="elevation-1"
    :loading="loading"
    loading-text="Загрузка... Подождите"
    show-select
    fixed-header
    >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Список автоматов</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
          ></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="newItem">Добавить</v-btn>
        <v-dialog v-model="formDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field type="number" v-model="editedItem.code_name" label="Порядковый номер"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.phone_nbr" label="Телефон"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.phone_imei" label="IMEI"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-text-field type="number" v-model="editedItem.water_min_value" label="Вода MIN"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-text-field type="number" v-model="editedItem.water_max_value" label="Вода MAX"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.comment" label="Комментарий"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="save">Записать</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay
          :value="isLoadingForm">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</div>
</template>
<script>
import { createHelpers } from 'vuex-map-fields'
import { toast } from "vue3-toastify"

const { mapFields } = createHelpers({
    getterType: 'automats/getField',
    mutationType: 'automats/updateField',
});

export default {
    name: 'v-automats',
    computed: {...mapFields([
                'items',
                'editedItem',
                'editedIndex',
                'defaultItem',
                'loading',
                'isLoadingForm',
                'textError',
                'errorSnackbar',
                'formDialog',
              ]),
               formTitle () {
                   return this.editedIndex === -1 ? 'Новый автомат' : 'Редактировать автомат'
               }
              },
      beforeMount() {
          this.$store.dispatch('automats/FORM')
      },
      created () {
          this.$store.dispatch('automats/GET_LIST')
      },
      watch: {
          errorSnackbar (newVal) {
              if (newVal) {
                  toast.error(this.textError, {
                      timeout: 10000,
                      icon: 'error',
                      dismissable: false,
                      showClose: true
                  })
              }
          },
          formDialog (val) {
              val || this.close()
          }
      },
      methods: {
          close () {
              this.$store.dispatch('automats/SET_FORMDIALOG', false)
          },
          editItem (item) {
              this.$store.dispatch('automats/SET_EDITED_ITEM', item)
          },
          newItem () {
              this.$store.dispatch('automats/SET_EDITED_ITEM', { item: this.defaultItem })
          },
          deleteItem (item) {
              confirm('Вы действительно хотите удалить автомат ?') &&
                  this.$store.dispatch('automats/DELETE', { item: item })
          },
          selectItem (isSelected, item) {
              this.$store.dispatch('automats/SET_SELECTED_ITEM', { item: item, isSelected: isSelected })
          },
          save () {
              if (this.editedItem.id === -1) {
                  this.$store.dispatch('automats/INSERT')
              } else {
                  this.$store.dispatch('automats/UPDATE')
              }
          },
      },
      data: () => ({
          headers: [
              { title: 'Порядковый номер', key: 'code_name', sortable: false },
              { title: 'Группы', key: 'group_name', sortable: false },
              { title: 'Юр лицо', key: 'company_name', sortable: false },
              { title: 'Номер модема', key: 'phone_nbr', sortable: false },
              { title: 'Imei модема', key: 'phone_imei', sortable: false },
              { title: 'Вода мин', key: 'water_min_value', sortable: false },
              { title: 'Вода макс', key: 'water_max_value', sortable: false },
              { title: 'Вода', key: 'water_value', sortable: false },
              { title: 'Комментарий', key: 'comment', sortable: false },
              { title: 'Действия', key: 'action', sortable: false }
          ]
      })
};
</script>>
