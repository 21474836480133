<template>
<div>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="imei"
    sort-by="imei"
    class="elevation-1"
    :loading="loading"
    loading-text="Загрузка... Подождите"
    show-select
    fixed-header
    >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Список групп автоматов</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
          ></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="newItem">Добавить</v-btn>
        <v-dialog v-model="formDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.name" label="Наименование"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                   <v-col cols="12" sm="12" md="12">
                     <v-combobox
                       v-model="selectedAutomat"
                       :items="automatList"
                       item-text="comment"
                       item-value="id"
                       label="Автоматы группы"
                       multiple
                       chips
                       return-object
                       >
                       <template v-slot:selection="{item}">
                         <v-chip
                           :key="item.id"
                           v-bind="item.comment"
                           :input-value="item.id"
                           @click:close="data.parent.selectItem(item)"
                           >
                           <v-avatar
                             class="accent white--text"
                             left
                             v-text="item.comment"
                             ></v-avatar>
                           {{ item.comment }}
                         </v-chip>
                       </template>
                     </v-combobox>
                   </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="save">Записать</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay
          :value="isLoadingForm">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</div>
</template>
<script>
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
    getterType: 'automatgroup/getField',
    mutationType: 'automatgroup/updateField',
});

export default {
    name: 'v-automatgroup',
    computed: {...mapFields([
        'items',
        'editedItem',
        'editedIndex',
        'defaultItem',
        'loading',
        'isLoadingForm',
        'textError',
        'errorSnackbar',
        'formDialog',
        'automatList',
        'selectedAutomat'
    ]),
        formTitle () {
            return this.editedIndex === -1 ? 'Новая группа' : 'Редактировать группу'
        }
    },
      beforeMount() {
          this.$store.dispatch('automatgroup/FORM')
      },
      created () {
          this.$store.dispatch('automatgroup/GET_LIST')
      },
      watch: {
          errorSnackbar (newVal) {
              if (newVal) {
                  this.$toast.error(this.textError, {
                      timeout: 10000,
                      icon: 'error',
                      dismissable: false,
                      showClose: true
                  })
              }
          },
          formDialog (val) {
              val || this.close()
          }
      },
      methods: {
          close () {
              this.$store.dispatch('automatgroup/SET_FORMDIALOG', false)
          },
          editItem (item) {
              this.$store.dispatch('automatgroup/SET_EDITED_ITEM', { item: item })
          },
          newItem () {
              this.$store.dispatch('automatgroup/SET_EDITED_ITEM', { item: this.defaultItem })
          },
          deleteItem () {
              confirm('Вы действительно хотите удалить автомат ?') &&
                  this.$store.dispatch('automatgroup/DELETE')
          },
          selectItem (isSelected, item) {
              this.$store.dispatch('automatgroup/SET_SELECTED_ITEM', {isSelected : isSelected, item : item})
          },
          save () {
              if (this.editedItem.id === -1) {
                  this.$store.dispatch('automatgroup/INSERT')
              } else {
                  this.$store.dispatch('automatgroup/UPDATE')
              }
          },
      },
      data: () => ({
          headers: [
              { text: 'Группы', value: 'name' },
              { text: 'Действия', value: 'action' , sortable: false }
          ]
      })
};
</script>>
