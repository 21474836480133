import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css';
import VueApexCharts from 'vue3-apexcharts'
//import { createI18n } from 'vue-i18n'

const app = createApp(App, {
//  delimiters: ['[[', ']]'],
  //render: h => h(App),
  data: () => ({
  })
})

//const i18n = createI18n({
//  locale: 'en',
//  fallbackLocale: 'en',
//})
//app.use(VueApexCharts)

//app.component('apexchart', VueApexCharts)

app.config.productionTip = false

//app.use(VueToastify)

/*new Vue({
  router,
  store,
  vuetify,
  delimiters: ['[[', ']]'],
  render: h => h(App),
  data: () => ({
  })
}).$mount('#app')
*/

app
  .use(router)
  .use(store)
  //.use(i18n)
  .use(vuetify)
  .use(Vue3Toastify)
  .use(VueApexCharts)
  .mount("#app");
