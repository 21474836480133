<template>
  <dashboard />
</template>

<script>
  import Dashboard from '../components/Dashboard.vue'

  export default {
    name: 'VDashboard',

    components: {
      Dashboard,
    },
  }
</script>
