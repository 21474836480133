<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      sort-by="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Загрузка... Подождите"
      show-select
      fixed-header
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Коды сообщений</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="newItem">Добавить</v-btn>
          <v-dialog v-model="formDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field v-model="editedItem.code" label="Код"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="6">
                      <v-text-field v-model="editedItem.description" label="Описание"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-switch v-model="editedItem.alert_flag" label="Тревога" class="pa-3"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-switch v-model="editedItem.sms_flag" label="СМС" class="pa-3"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p1" label="Параметр 1"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p2" label="Параметр 2"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p3" label="Параметр 3"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p4" label="Параметр 4"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p5" label="Параметр 5"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p6" label="Параметр 6"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p7" label="Параметр 7"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p8" label="Параметр 8"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p9" label="Параметр 9"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="4">
                      <v-text-field v-model="editedItem.p10" label="Параметр 10"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="12">
                      <v-text-field v-model="editedItem.comment" label="Комментарий" class="pa-3"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Записать</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-overlay
            :value="isLoadingForm">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.alert_flag`]="{ item }">
        <v-simple-checkbox
          v-model="item.alert_flag"
          disabled
          ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.sms_flag`]="{ item }">
        <v-simple-checkbox
          v-model="item.sms_flag"
          disabled
          ></v-simple-checkbox>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
    getterType: 'events/getField',
    mutationType: 'events/updateField',
});

export default {
    name: 'v-events',
    computed: {...mapFields([
                'items',
                'editedItem',
                'editedIndex',
                'defaultItem',
                'loading',
                'isLoadingForm',
                'textError',
                'errorSnackbar',
                'formDialog',
        ]),
        formTitle () {
          return this.editedIndex === -1 ? 'Новая ошибка' : 'Редактировать ошибку'
        }
    },
    beforeMount() {
        this.$store.dispatch('events/FORM')
    },
    created () {
        this.$store.dispatch('events/GET_LIST')
    },
    watch: {
        errorSnackbar (newVal) {
            if (newVal) {
                this.$toast.error(this.textError, {
                    timeout: 10000,
                    icon: 'error',
                    dismissable: false,
                    showClose: true
                })
            }
        },
        formDialog (val) {
            val || this.close()
        }
    },
    methods: {
        close () {
              this.$store.dispatch('events/SET_FORMDIALOG', false)
          },
          editItem (item) {
              this.$store.dispatch('events/SET_EDITED_ITEM', item)
          },
          newItem () {
              this.$store.dispatch('events/SET_EDITED_ITEM', { item: this.defaultItem })
          },
          deleteItem (item) {
              confirm('Вы действительно хотите удалить событие ?') &&
                  this.$store.dispatch('events/DELETE', { item: item })
          },
          selectItem (isSelected, item) {
              this.$store.dispatch('events/SET_SELECTED_ITEM', { item: item, isSelected: isSelected })
          },
          save () {
              if (this.editedItem.id === -1) {
                  this.$store.dispatch('events/INSERT', { item: this.editedItem })
              } else {
                  this.$store.dispatch('events/UPDATE', { item: this.editedItem })
              }
          },
    },
    data: () => ({
        headers: [
            { title: 'Код', key: 'code', sortable: false },
            { title: 'Описание', key: 'description', sortable: false },
            { title: 'Тревога', key: 'alert_flag', sortable: false },
            { title: 'СМС', key: 'sms_flag', sortable: false },
            { title: 'Комментарий', key: 'comment', sortable: false },
            { title: 'Action', key: 'action', sortable: false }
        ]
    })
};
</script>>
