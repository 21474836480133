<template>
  <Monitor></Monitor>
</template>
<script>
// @ is an alias to /src
import Monitor from '@/components/Monitor.vue'

export default {
    name: 'VMonitor',
    components: {
        Monitor
    }
}
</script>
