<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <apexchart width="800" type="bar" :options="options" :series="series"></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields'

  const { mapFields } = createHelpers({
      getterType: 'dashboard/getField',
      mutationType: 'dashboard/updateField',
  });

  export default {
      name: 'v-dashboard',
      computed: {...mapFields([
          'loading',
          'textError',
          'errorSnackbar',
          'options',
          'series'
        ])
                },
      created () {
          var self = this;
          self.$store.dispatch('dashboard/GET_LIST')
      },
      data: () => ({
      }),
  }
</script>
