<template>
  <Taretype></Taretype>
</template>
<script>
// @ is an alias to /src
import Taretype from '@/components/Taretype.vue'

export default {
    name: 'v-taretype',
    components: {
        Taretype
    }
}
</script>
