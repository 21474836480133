<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="automat"
      sort-by="automat"
      class="elevation-1"
      :loading="loading"
      loading-text="Загрузка... Подождите"
      :items-per-page="-1"
      :item-class="itemRowBackground"
      fixed-header
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Связь</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
            ></v-divider>
          <v-spacer></v-spacer>
          <v-overlay
            :value="isLoadingForm">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-toolbar>
      </template>
      <template v-slot:[`item.event_date`]="{ item }">
        <span>{{new Date(item.columns.event_date).toISOString().slice(0,19).replace(/T/, ' ')}}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
/* global cotonic */
import { createHelpers } from 'vuex-map-fields'
import { toast } from "vue3-toastify"

const { mapFields } = createHelpers({
    getterType: 'connections/getField',
    mutationType: 'connections/updateField',
});

export default {
    name: 'v-connections',
    computed: {...mapFields([
        'items',
        'loading',
        'isLoadingForm',
        'textError',
        'errorSnackbar'
    ])},
    created () {
        var self = this;
        self.$store.dispatch('connections/GET_LIST')
        self.subscribe()
    },
    mounted () {
        this.ping()
    },
    methods: {
        subscribe() {
            var self = this
            cotonic.broker.subscribe("bridge/origin/public/connections", (function(msg) {
                self.$store.dispatch('connections/UNSHIFT', msg.payload)
            }).bind(self))
            cotonic.broker.subscribe("bridge/origin/public/bank", (function(msg) {
                self.$store.dispatch('connections/BANK', msg.payload)
            }).bind(self))
        },
        ping() {
            var self = this
            return setTimeout(() => {
                    self.$store.dispatch('connections/PING')
                    self.ping()
                }, 1000)
        },
        itemRowBackground(Item) {
            if (Item.no_connected)
                return 'red'
        }
    },
    watch: {
        errorSnackbar (newVal) {
            if (newVal) {
                  toast.error(this.textError, {
                      timeout: 10000,
                      icon: 'error',
                      dismissable: false,
                      showClose: true
                  })
            }
        },
        formDialog (val) {
            val || this.close()
        }
    },
    data: () => ({
        formTitle: '',
        headers: [
            { title: 'Автомат', key: 'automat', sortable: true },
            { title: 'Номер телефона', key: 'phone_nbr', sortable: false },
            { title: 'IMEI', key: 'phone_imei', sortable: false },
            { title: 'Последний сеанс', key: 'event_date', sortable: true },
            { title: 'Купюры', key: 'p1', sortable: false },
            { title: 'КБ1', key: 'p2', sortable: false },
            { title: 'КБ2', key: 'p3', sortable: false },
            { title: 'КБ3', key: 'p4', sortable: false },
            { title: 'КБ4', key: 'p5', sortable: false },
            { title: 'КБ5', key: 'p6', sortable: false },
            { title: 'КБ6', key: 'p7', sortable: false },
            { title: 'КБ7', key: 'p8', sortable: false },
            { title: 'КБ8', key: 'p9', sortable: false },
            { title: 'КБ9', key: 'p10', sortable: false }
        ]
    })
};
</script>>
