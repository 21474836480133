<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title v-slot="{ open }">
          <v-row no-gutters>
            <v-col cols="3">
              Настройки
            </v-col>
            <v-col
              cols="8"
              class="text--secondary"
              >
              <v-fade-transition leave-absolute>
                <span v-if="open">Установите настройки</span>
                <v-row
                  v-else
                  no-gutters
                  style="width: 100%"
                  >
                  <v-col cols="4">
                    Киоск: {{ selectListAutomat.comment || 'Не выбран' }}
                  </v-col>
                  <v-col cols="4">
                    Дата начала: {{ startDate || 'Не выбран' }}
                  </v-col>
                  <v-col cols="4">
                    Дата конца: {{ endDate || 'Не выбран' }}
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row
            justify="space-around"
            no-gutters
          >
            <v-col cols="3">
              <v-menu
                ref="kioskMenu"
                :close-on-content-click="false"
                :return-value.sync="selectListAutomat.comment"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ props }">
                  <v-select
                    v-model="selectListAutomat"
                    :items="listPropertyAutomat"
                    label="Киоск"
                    item-title="comment"
                    item-value="id"
                    return-object
                    single-line
                    required
                    clearable
                    v-bind="props"
                    >
                  </v-select>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="startDate"
                label="Дата начала"
                type="date"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="endDate"
                label="Дата конца"
                type="date"
                ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                icon
                color="green"
                @click="refreshreport"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="time"
      class="elevation-1"
      :loading="loading"
      loading-text="Загрузка... Подождите"
      :items-per-page="-1"
      fixed-header
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Реестр инкассаций</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
            ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="formDialog" max-width="500px" @click:outside="close" transition="dialog-bottom-transition">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.time" label="Дата инкассации" disabled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        dense
                        >
                        <template v-slot:activator="{ props }">
                          <v-text-field
                            v-model="editedItem.report_time"
                            label="Дата отчета"
                            prepend-icon="mdi-calendar"
                            dense
                            readonly
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker dense locale="ru-RU" v-model='editedItem.report_time' :format="'YYYY.MM.DD'" :input-placeholder="'DD.MM.YYYY'"   label="дата отчета" @click="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.code_name" label="Номер киоска" disabled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.automat" label="Киоск" disabled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field type="number" v-model="editedItem.amount" label="Принятая сумма"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <v-checkbox v-model="editedItem.no_money" label="Деньги не сдавал" class="pa-3"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="selectListCashier"
                        :items="listPropertyCashier"
                        label="Кассир"
                        item-title="name"
                        item-value="id"
                        return-object
                        single-line
                        required
                        >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="selectListCollector"
                        :items="listPropertyCollector"
                        label="Инкассатор"
                        item-title="name"
                        item-value="id"
                        return-object
                        single-line
                        required
                        >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-text-field  v-model="editedItem.comments" label="Комментарий"></v-text-field>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Записать</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-overlay :value="isLoadingForm">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        </v-toolbar>
      </template>
      <template v-slot:item.time="{ item }">
        <span>{{new Date(item.columns.time).toISOString().slice(0,19).replace(/T/, ' ')}}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script setup>
  import { ref } from 'vue'

  const menu = ref()

  const date = ref((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
  const menuActive = ref(false)
  const modal = ref(false)
  const menu2 = ref(false)
</script>
<script>
/* global cotonic */
import { createHelpers } from 'vuex-map-fields'
import { toast } from "vue3-toastify"

const { mapFields } = createHelpers({
    getterType: 'collections/getField',
    mutationType: 'collections/updateField',
});

export default {
    name: 'v-collections',
    computed: {...mapFields([
        'items',
        'loading',
        'isLoadingForm',
        'textError',
        'errorSnackbar',
        'listPropertyCashier',
        'listPropertyAutomat',
        'selectListCashier',
        'selectListAutomat',
        'listPropertyCollector',
        'selectListCollector',
        'formDialog',
        'editedItem',
        'editedIndex',
        'startDate',
        'endDate'
    ])},
    created () {
        var self = this;
        self.$store.dispatch('collections/GET_LIST')
        self.subscribe()
        self.loadAutomats()
    },
    methods: {
        subscribe() {
            var self = this
            cotonic.broker.subscribe("bridge/origin/public/collections", (function(msg) {
                self.$store.dispatch('collections/UNSHIFT', msg.payload)
            }).bind(self))
        },
        editItem (item) {
            this.$store.dispatch('collections/SET_EDITED_ITEM', item)
        },
        close () {
            this.$store.dispatch('collections/SET_FORMDIALOG', false)
        },
        save () {
            if (this.editedItem.id !== -1) {
                this.$store.dispatch('collections/UPDATE', Object.assign(this.editedItem, {collector_id : this.selectListCollector.id}, {cashier_id : this.selectListCashier.id}))
            }
        },
        refreshreport () {
            this.$store.dispatch('collections/GET_LIST')
        },
        loadAutomats () {
            this.$store.dispatch('collections/SET_AUTOMATS')
        }
    },
    watch: {
        errorSnackbar (newVal) {
            if (newVal) {
                toast.error(this.textError, {
                    timeout: 10000,
                    icon: 'error',
                    dismissable: false,
                    showClose: true
                })
            }
        },
        formDialog (val) {
            val || this.close()
        }
    },
    data: () => ({
      formTitle: '',
      menu: false,
      menuActive: false,
      modal: false,
      headers: [
        { title: 'Автомат', key: 'automat', width: '25%', sortable: true },
        { title: 'Дата инкассации', key: 'time', width: '20%', sortable: true},
        { title: 'Дата отчета', key: 'report_time', width: '20%', sortable: true },
        { title: 'Монет в кэшбоксе, руб', key: 'coins_in_tray', width: '10%' },
        { title: 'Монет в банке, руб', key: 'coins' , width: '10%'},
        { title: 'Купюр в кэшбоксе, руб', key: 'banknote_before' , width: '10%'},
        { title: 'Купюр в кэшбоксе, шт.', key: 'banknote_before_count' , width: '10%'},
        { title: 'Сумма 1,руб', key: 'summa_p1' , width: '10%'},
        { title: 'Сумма 2,руб', key: 'summa_p2' , width: '10%'},
        { title: 'Дельта,руб', key: 'delta' , width: '10%'},
        { title: 'Инкассируемая сумма,руб', key: 'sales' , width: '10%'},
        { title: 'Безналичная оплата,руб', key: 'beznal' , width: '10%'},
        { title: 'Принятая сумма', key: 'amount' , width: '10%'},
        { title: 'Разница', key: 'delta' , width: '10%'},
        { title: '% Монеты', key: 'pr_money' , width: '5%'},
        { title: '% Банкноты', key: 'pr_banknote' , width: '5%'},
        { title: 'Кассир', key: 'cashier' , width: '10%'},
        { title: 'Инкассатор', key: 'collector' , width: '10%'},
        { title: 'Действия', key: 'action' , width: '5', sortable: false }
      ]
    })
};
</script>>
