<template>
  <Events></Events>
</template>
<script>
// @ is an alias to /src
import Events from '@/components/Events.vue'

export default {
    name: 'VEvents',
    components: {
        Events
    }
}
</script>
