import { getField, updateField } from 'vuex-map-fields'

const state = {
	items: [],
	selectedItems: [],
    loading: false,
    isLoadingForm: false,
	errorSnackbar: '',
	textError: '',
	listPropertyAutomat: [],
	selectListAutomat: {id: '', comment: ''},
	startDate: null,
	endDate: null
}

const getters = {
	LIST: state => {
		return state.items
	},
	GETLOADINGFORM: state => {
		return state.isLoadingForm
	},
	GETEDITEDITEM: state => {
		return state.editedItem
	},
	GETEDITEDINDEX: state => {
		return state.editedIndex
	},
	GETLOADING: state => {
		return state.loading
	},
    GETTEXTERROR: state => {
		return state.textError
	},
	GETERRORSNACKBAR: state => {
		return state.errorSnackbar
	},
    getField
}

const mutations = {
    SET_LOADING: (state, payload) => {
		state.loading = payload
	},
	SET_FORMLOADING: (state, payload) => {
		state.isLoadingForm = payload
	},
    SET_LIST: (state, payload) => {
		state.items = payload
		state.loading = false
	},
    SET_TEXTERROR: (state, payload) => {
		state.textError = payload
		state.errorSnackbar = true
		state.isLoadingForm = false
	},
    INSERT: (state, payload) => {
		//Vue.set(state.items, state.editedIndex, payload.item)
		state.items[state.editedIndex] = payload.item
        state.loading = false
		state.formDialog = false
	},
	UNSHIFT: (state, payload) => {
		state.items.unshift(payload)
		//Vue.set(state, 'items', state.items)
	},
	SET_SNACKBAR: (state, payload) => {
		state.errorSnackbar = payload
	},
	SET_AUTOMATS: (state, payload) => {
		state.listPropertyAutomat = payload
	},
    updateField
}

const actions = {
    GET_LIST: async (context) => {
        /*await this.axios.post('/api/model/automats/post/list', {})
                  .then(data => {
                      if (data.data.status === 'ok') {
                          context.commit('SET_LIST', data.data.result)
                      }
                  })
                  .catch(error => {
                      context.commit('SET_TEXTERROR', error)
                      context.commit('SET_LOADING', false)
                  })*/
        let response = await fetch('/api/model/messages_event/post/list', {
            method: 'POST',
            //mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                automat : context.state.selectListAutomat.id,
                data : {
                    startDate: context.state.startDate,
                    endDate: context.state.endDate
                }
            })
        });
        try {
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'ok') {
                    context.commit('SET_LIST', result.result)
                }
            }
            else {
                context.commit('SET_TEXTERROR', response.statusText)
                context.commit('SET_LOADING', false)
                throw new Error(`Request failed with status ${response.status}`);
            }
        }
        catch(error)  {
            console.error(`Error in load function for : ${error}`)
            context.commit('SET_TEXTERROR', error)
        }
    },
    DOWNLOAD_LIST: async (context) => {

    },
	SET_AUTOMATS: async (context) => {
		/*await this.axios.post('/api/model/automats/post/list', {})
			.then(data => {
				if (data.status === 200) {
					context.commit('SET_AUTOMATS', data.data.result)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})*/
        let response = await fetch('/api/model/automats/post/list', {
            method: 'POST',
            //mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

            })
        });
        try {
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'ok') {
                    context.commit('SET_AUTOMATS', result.result)
                }
            }
            else {
                context.commit('SET_TEXTERROR', response.statusText)
                context.commit('SET_LOADING', false)
                throw new Error(`Request failed with status ${response.status}`);
            }
        }
        catch(error)  {
            console.error(`Error in load function for : ${error}`)
            context.commit('SET_TEXTERROR', error)
        }
	},
	UNSHIFT: async (context, payload) => {
        console.log(context.state.selectListAutomat)
        console.log(payload)
        if (context.state.selectListAutomat.id !== '') {
            if (payload.automat.includes(context.state.selectListAutomat.comment))
                context.commit('UNSHIFT', payload)
        }
        else
        {
            context.commit('UNSHIFT', payload)
        }
	},
	INSERT: async (context, payload) => {
		context.commit('SET_FORMLOADING', true)
		/*await this.axios.post('/api/model/events/post/insert',
			payload.item
		)
			.then(() => {
				context.commit('INSERT', payload)
				context.commit('SET_FORMLOADING', false)
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
			})*/
        let response = await fetch('/api/model/events/post/insert', {
            method: 'POST',
            //mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                payload.item
            )
        });
        try {
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'ok') {
                    context.commit('INSERT', result.result)
                    context.commit('SET_FORMLOADING', false)
                }
            }
            else {
                context.commit('SET_TEXTERROR', response.statusText)
                context.commit('SET_LOADING', false)
                throw new Error(`Request failed with status ${response.status}`);
            }
        }
        catch(error)  {
            console.error(`Error in load function for : ${error}`)
            context.commit('SET_TEXTERROR', error)
        }
	},
	SET_SNACKBAR: async (context, payload) => {
		context.commit('SET_SNACKBAR', payload)
	}
}

export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations
}
