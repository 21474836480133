import {createRouter, createWebHistory} from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import Automats from '../views/Automats.vue'
import Taretype from '../views/Taretype.vue'
import Events from '../views/Events.vue'
import Monitor from '../views/Monitor.vue'
import Alert from '../views/Alert.vue'
import Connections from '../views/Connections.vue'
import Collections from '../views/Collections.vue'
import Automatgroup from '../views/Automatgroup.vue'
import People from '../views/People.vue'
import Remains from '../views/Remains.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView
  },
  {
		path: '/automats',
		component: Automats
	},
  {
		path: '/automatgroup',
		component: Automatgroup
	},
  {
		path: '/taretype',
		component: Taretype
	},
  {
		path: '/events',
		component: Events
	},
  {
		path: '/monitor',
		component: Monitor
	},
  {
		path: '/alert',
		component: Alert
	},
  {
		path: '/connections',
		component: Connections
	},
  {
		path: '/remains',
		component: Remains
	},
  {
		path: '/collections',
		component: Collections
	},
  {
		path: '/people',
		component: People
	},
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//Vue.use(router)

export default router
