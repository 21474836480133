<template>
  <Collections></Collections>
</template>
<script>
// @ is an alias to /src
import Collections from '@/components/Collections.vue'

export default {
    name: 'VCollections',
    components: {
        Collections
    }
}
</script>
