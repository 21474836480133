<template>
  <People></People>
</template>
<script>
// @ is an alias to /src
import People from '@/components/People.vue'

export default {
    name: 'VPeople',
    components: {
        People
    }
}
</script>
