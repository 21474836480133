<template>
  <Remains></Remains>
</template>
<script>
// @ is an alias to /src
import Remains from '@/components/Remains.vue'

export default {
	name: 'v-remains',
	components: {
		Remains
	}
}
</script>
