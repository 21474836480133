import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'

const state = {
	items: [],
	selectedItems: [],
	editedItem: {},
	editedIndex: -1,
    loading: false,
    isLoadingForm: false,
    formDialog: false,
	listPropertyAutomat: [],
	selectListAutomat: {id: '', comment: ''},
	startDate: null,
	endDate: null
}

const getters = {
	LIST: state => {
		return state.items
	},
	GETLOADINGFORM: state => {
		return state.isLoadingForm
	},
	GETLOADING: state => {
		return state.loading
	},
    GETTEXTERROR: state => {
		return state.textError
	},
	GETERRORSNACKBAR: state => {
		return state.errorSnackbar
	},
    getField
}

const mutations = {
    SET_LOADING: (state, payload) => {
		state.loading = payload
	},
	SET_FORMLOADING: (state, payload) => {
		state.isLoadingForm = payload
	},
	SET_FORMDIALOG: (state, payload) => {
		state.formDialog = payload
		state.editedItem = Object.assign({}, state.defaultItem)
	},
    SET_LIST: (state, payload) => {
		state.items = payload
		state.loading = false
	},
	FORM: (state, payload) => {
		state.forma = payload
	},
    SET_TEXTERROR: (state, payload) => {
		state.textError = payload
		state.errorSnackbar = true
		state.isLoadingForm = false
	},
	SET_SNACKBAR: (state, payload) => {
		state.errorSnackbar = payload
	},
	SET_AUTOMATS: (state, payload) => {
		state.listPropertyAutomat = payload
	},
    updateField
}

const actions = {
		GET_LIST: async (context) => {
		//context.commit('SET_LOADING', true)
			await axios.post('/api/model/remains/post/list',{
				automat : context.state.selectListAutomat.id,
				data : {
					startDate: context.state.startDate,
					endDate: context.state.endDate
				}
			})
				.then(data => {
					if (data.status === 200) {
						context.commit('SET_LIST', data.data.result)
					}
				})
				.catch(error => {
					context.commit('SET_TEXTERROR', error)
					context.commit('SET_LOADING', false)
				})
	},
	SET_AUTOMATS: async (context) => {
		await axios.post('/api/model/automats/post/list', {})
			.then(data => {
				if (data.status === 200) {
					context.commit('SET_AUTOMATS', data.data.result)
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})
	},
	FORM: async (context, payload) => {
		context.commit('FORM', payload)
	},
	SET_SNACKBAR: async (context, payload) => {
		context.commit('SET_SNACKBAR', payload)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
