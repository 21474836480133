import Vue from 'vue'
import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'

const state = {
    options: {
        chart: {
            id: 'vuechart-example'
        },
        xaxis: {
            categories: []
        }
    },
    series: [{
        name: 'series-1',
        data: []
    }],
    loading: false,
	errorSnackbar: '',
	textError: ''
}

const getters = {
	OPTIONS: state => {
		return state.options
	},
	SERIES: state => {
		return state.series
	},
	GETLOADING: state => {
		return state.loading
	},
    GETTEXTERROR: state => {
		return state.textError
	},
	GETERRORSNACKBAR: state => {
		return state.errorSnackbar
	},
    getField
}

const mutations = {
    SET_LOADING: (state, payload) => {
		state.loading = payload
	},
    SET_OPTIONS: (state, payload) => {
		state.options = payload
		state.loading = false
	},
    SET_SERIES: (state, payload) => {
        state.series = payload
        state.loading = false
    },
    SET_TEXTERROR: (state, payload) => {
		state.textError = payload
		state.errorSnackbar = true
		state.isLoadingForm = false
	},
	SET_SNACKBAR: (state, payload) => {
		state.errorSnackbar = payload
	},
    updateField
}

const actions = {
	GET_LIST: async (context) => {
		//context.commit('SET_LOADING', true)
	    await axios.post('/api/model/remains/post/list',{
			automat : null,
			data : {
				startDate: null,
				endDate: null
			}
		})
			.then(data => {
				if (data.status === 200) {
                    const categories = data.data.result.map((item) => item.comment)
                    const ost = data.data.result.map((item) => item.ost)
					context.commit('SET_OPTIONS', {
                                                    chart: {
                                                            id: 'vuechart-example'
                                                    },
                                                    xaxis: {
                                                        categories: categories
                                                    }
                                                    })
                    context.commit('SET_SERIES', [{
                                                    name: 'ost',
                                                    data: ost
                    }])
				}
			})
			.catch(error => {
				context.commit('SET_TEXTERROR', error)
				context.commit('SET_LOADING', false)
			})
	},
	SET_SNACKBAR: async (context, payload) => {
		context.commit('SET_SNACKBAR', payload)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
